
export default function AboutMe() {
  return (
    <div>
      <div className="card-content">
        <h2 id="aboutMe">About Me</h2>
        <div className="about-description">
          <p>
            Hi! I'm Ashley, a highly motivated and passionate individual with a
            strong desire to learn and advance in the field of software
            engineering. As a former healthcare provider, I am experienced in
            solving complex problems and helping people achieve desired goals. I
            am confident that my skill set and natural inclinations make me a
            perfect candidate to succeed as a software engineer. I am eager to
            join a team that can provide me with the structure and resources
            necessary to fully develop my technical expertise and further hone
            my problem-solving abilities.
          </p>
        </div>
      </div>
    </div>
  );
} 
