import "./Hero.css"
import IMG_0949 from "../assets/images/IMG_0949.png"


export default function Hero(){
    return (
      <div className="hero-container">
        <div className="hero-headline">
          <p className="hero-name">Ashley Dennis</p>
          <p className="hero-title">Software Engineer</p>
        </div>
        <img
          className="headshot"
          src={IMG_0949}
          alt="woman smiling and standing by blue chair"
        />
      </div>
    );

}