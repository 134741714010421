import {GithubOutlined, GoogleCircleFilled, LinkedinOutlined} from "@ant-design/icons"

export default function Contact(){

    return (
      <>
        <div className="card-content">
          <h2 id="contact">Connect With Me</h2>
          <div className="contact-section">
            <a
              className="contact-section_link"
              href="https://www.linkedin.com/in/ashley-dennis-dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinOutlined />
            </a>
            <a
              className="contact-section_link"
              href="https://github.com/AshleyDennisDev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubOutlined />
            </a>
            <a
              className="contact-section_link"
              href="mailto:adenn1852@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GoogleCircleFilled />
            </a>
          </div>
        </div>
      </>
    );
}