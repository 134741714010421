import './App.css';
// import Header from './Components/header/Header';
import AboutMe from './Components/AboutMe';
import Contact from './Components/Contact';
import Hero from './Components/Hero';
import Projects from './Components/Projects';


function App() {
  return (
    <div className='app'>
     {/* <Header/> */}
     <Hero/>
      <div className='app-body'>
      <AboutMe/>
      <Projects/>
       <Contact/>
      </div>
      <p className='copy'>&copy; 2023, Ashley Dennis</p>
    </div>
  );
}

export default App;
