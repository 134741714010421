import { Card, Image } from "antd";
import brunchVibesImg from "../assets/images/brunchVibesImg.png"
import muchTodoImg from "../assets/images/muchTodoImg.png"
import dadJokes from "../assets/images/dadJokes.png"
import { GithubOutlined, LinkOutlined } from "@ant-design/icons";
const { Meta } = Card;

export default function Projects() {
  return (
    <div className="card-content">
      <h2 id="projects">Projects</h2>
      <div className="projects-section">
        <Card
          className="projects-section_card"
          size="default"
          cover={
            <Image
              alt="example"
              src={brunchVibesImg}
              className="projects-section_image"
            />
          }
          actions={[
            <a
              href="https://brunch-vibes.web.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOutlined key="link" />
            </a>,
            <a
              href="https://github.com/AshleyDennisDev/brunch-vibes-web"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubOutlined />
            </a>,
          ]}
        >
          <div className="projects-section_description">
          <Meta
            title="Brunch Vibes"
            description="Catch a vibe using this Fullstack App developed with React, Node, Express, Firestore, Google Auth, and deployed using Firebase"
          />
          </div>
        </Card>
        <Card
          className="projects-section_card"
          size="default"
          cover={
            <Image
              alt="example"
              src={muchTodoImg}
              className="projects-section_image"
            />
          }
          actions={[
            <a
              href="https://much-todo-ad.web.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOutlined key="link" />
            </a>,
            <a
              href="https://github.com/AshleyDennisDev/todo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubOutlined />
            </a>,
          ]}
        >
          <div className="projects-section_description">
          <Meta
            title="Much ToDo"
            description="So Much ToDo, another Fullstack App created using React, Node, Express, Firestore, and deployed using Firebase"
          />
          </div>
        </Card>
        <Card
          className="projects-section_card"
          size="default"
          cover={
            <Image
              alt="example"
              src={dadJokes}
              className="projects-section_image"
            />
          }
          actions={[
            <a
            href="https://chucklewithdad.netlify.app/"
            target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOutlined key="link" />
            </a>,
            <a
              href="https://github.com/AshleyDennisDev/dad-jokes-app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubOutlined />
            </a>
          ]}
        >
          <div className="projects-section_description">
           <Meta
            title="Chuckles With Dad"
            description="Frontend app using Vue JS, retrieving data from third-party api, styled with Tailwind, and deployed using netlify "
          />
          </div>
        </Card>
      </div>
    </div>
  );
}
